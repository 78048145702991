/* =====================
   Animation Keyframes
===================== */
@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes fadeInLeft {
  from {
    opacity: 0;
    transform: translateX(-20px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes fadeInRight {
  from {
    opacity: 0;
    transform: translateX(20px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

/* New animation for circles */
@keyframes floatCircle {
  0% {
    transform: translate(0, 0);
  }
  50% {
    transform: translate(20px, -20px);
  }
  100% {
    transform: translate(0, 0);
  }
}

/* =====================
   Container Styles
===================== */
.startQuizContainer {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  position: relative;
  overflow-x: hidden;
  max-width: 100vw;
}

/* Decorative Circles */
.decorativeCircle {
  position: absolute;
  border-radius: 50%;
  left: -200px;
  border: 2px solid #011835;
  opacity: 0.5;
  pointer-events: none;
  animation: floatCircle 8s ease-in-out infinite;
}

.topCircle {
  width: 350px;
  height: 350px;
  top: 34%;
  animation-delay: 0s;
}

.bottomCircle {
  width: 300px;
  height: 300px;
  top: 55%;
  animation-delay: -4s; /* Offset animation to create alternating effect */
}

/* Main Content Area */
.mainContent {
  flex: 1;
  padding: 2rem 4rem;
  max-width: 1000px;
  margin: 0 auto;
  width: 100%;
}

/* =====================
   Header Section
===================== */
.headerSection {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 4rem;
}

.logo {
  height: 40px;
  width: auto;
  animation: fadeInLeft 0.8s ease forwards;
}

.languageSelectorWrapper {
  position: relative;
  display: inline-block;
  border: 2px solid; /* Border color set inline */
  border-radius: 20px;
  height: 36px;
  width: auto;
  animation: fadeInRight 0.8s ease forwards;
  overflow: hidden;
}

/* The actual native select element - hidden but functional */
.nativeSelect {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0; /* Make it invisible */
  cursor: pointer;
  z-index: 2; /* Above the custom selector */
  padding: 0;
  margin: 0;
  border: none;
}

/* The visible custom selector */
.customSelector {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 16px;
  height: 100%;
  box-sizing: border-box;
  position: relative;
  z-index: 1;
  cursor: pointer;
}

.selectedValue {
  margin-right: 10px;
  font-size: 14px;
  font-weight: 500;
}

.arrowIcon {
  font-size: 10px;
}

/* Create an overlay for the entire clickable area */
.clickOverlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1; /* Below the select but above other elements */
  cursor: pointer;
}

/* Hide default arrow in IE/Edge */
.languageSelector::-ms-expand {
  display: none;
}
/* =====================
   Content Section
===================== */
.contentSection {
  display: flex;
  flex-direction: column;
  gap: 3rem;
}

/* Title Row Styling */
.titleRow {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  gap: 3rem;
}

.mainTitle {
  font-size: 3rem;
  font-weight: 400;
  margin: 0;
  flex: 1;
  opacity: 0;
  animation: fadeInUp 0.8s ease forwards;
  animation-delay: 0.2s;
}

.tagline {
  font-size: 2rem;
  line-height: 1.3;
  margin: 0;
  font-weight: bold;
  flex: 1;
  opacity: 0;
  animation: fadeInUp 0.8s ease forwards;
  animation-delay: 0.4s;
}

/* Info Boxes Styling */
.infoBoxContainer {
  display: flex;
  gap: 1.5rem;
}

.infoBox {
  padding: 1.5rem 2rem;
  border-bottom-left-radius: 50px;
  border-top-right-radius: 50px;
  position: relative;
  overflow: hidden;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.infoBox:first-child {
  opacity: 0;
  animation: fadeInLeft 0.8s ease forwards;
  animation-delay: 0.6s;
}

.infoBox:last-child {
  opacity: 0;
  animation: fadeInRight 0.8s ease forwards;
  animation-delay: 0.6s;
}

.infoBox:hover {
  transform: translateY(-5px);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
}

.infoTitle {
  font-size: 3rem;
  margin: 0;
  font-weight: 500;
  line-height: 1.1;
}

.infoText {
  font-size: 1.25rem;
  margin: 0;
  line-height: 1.2;
  font-weight: 400;
}

/* Description Section */
.descriptionRow {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  gap: 4rem;
}

.description {
  font-size: 1.125rem;
  line-height: 1.6;
  color: #4A5568;
  margin: 0 auto;
  flex: 1;
  max-width: 800px;
  text-align: left;
  width: 100%;
  padding: 0 1rem;
  box-sizing: border-box;
  opacity: 0;
  animation: fadeInUp 0.8s ease forwards;
  animation-delay: 0.8s;
}

/* Start Button Styling */
.startButton {
  padding: 1rem 3rem;
  margin-top: 2rem;
  font-size: 1.25rem;
  font-weight: 500;
  border: none;
  border-radius: 2rem;
  cursor: pointer;
  transition: all 0.3s ease;
  white-space: nowrap;
  opacity: 0;
  animation: fadeInUp 0.8s ease forwards;
  animation-delay: 1s;
}

.startButton:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
  opacity: 0.80;
}

/* Footer Styling */
.footer {
  width: 100%;
  padding: 8px 0;
  margin-top: auto;
  flex-shrink: 0;
}

.poweredBy {
  font-size: 14px;
  font-weight: 700;
  color: #4E529A;
  display: flex;
  align-items: center;
  justify-content: center;
}

.workxplorerIcon {
  height: 20px;
  margin-left: 8px;
}

/* =====================
   Responsive Styles
===================== */

/* Large Tablets and Small Desktops */
@media (max-width: 1024px) {
  .mainContent {
    padding: 1rem;
    max-width: 100%;
    box-sizing: border-box;
  }

  .headerSection {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0 1rem;
    margin-bottom: 3rem;
    width: 100%;
    box-sizing: border-box;
  }

  .logo {
    height: 32px;
  }

  /* Adjust layout for smaller screens */
  .titleRow {
    flex-direction: column;
    align-items: flex-start;
    gap: 1rem;
    width: 100%;
    max-width: 500px;
    margin: 0 auto;
  }

  .mainTitle {
    font-size: 2.3rem;
    width: 100%;
    text-align: left;
  }

  .tagline {
    font-size: 1.7rem;
    width: 100%;
    text-align: right;
    margin-top: 1rem;
  }

  /* Adjust info boxes for tablet view */
  .infoBoxContainer {
    flex-direction: column;
    gap: 1rem;
    width: 100%;
    max-width: 700px;
    margin: auto;
    box-sizing: border-box;
  }

  .infoBox {
    width: 100%;
    padding: 2rem;
    background-color: #011835;
    margin: 0;
    box-sizing: border-box;
  }

  .infoBox:first-child,
  .infoBox:last-child {
    border-top-right-radius: 50px;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 50px;
    border-top-left-radius: 0;
    width: 60%;
  }

  .infoBox:last-child {
    align-self: flex-end;
    margin-top: -0.5rem;
  }

  .infoTitle {
    font-size: 2.5rem;
    margin: 0;
    font-weight: 500;
    line-height: 1.1;
  }
  
  .infoText {
    font-size: 1.25rem;
    line-height: 1.2;
    font-weight: 400;
  }

  /* Adjust description and button for tablet */
  .descriptionRow {
    flex-direction: column;
    align-items: center;
    gap: 2rem;
  }

  .description {
    font-size: 0,7rem;
    text-align: center;
    width: 100%;
  }

  .startButton {
    order: -1; /* Move button before description */
    width: 100%;
    max-width: 480px;
    padding: 1rem;
    border-radius: 100px;
    background-color: #068D6C;
    margin: 0 auto;
  }
}

/* Tablets */
@media (max-width: 768px) {
  .headerSection {
    margin-bottom: 2.5rem;
    padding: 0 1rem;
  }

  .logo {
    height: 30px;
  }

  .infoTitle {
    font-size: 1.9rem;
  }
  
  .infoText {
    font-size: 1rem;
  }
}

/* Mobile Phones */
@media (max-width: 480px) {
  .startQuizContainer {
    max-width: 100vw;
    overflow-x: hidden;
    box-sizing: border-box;
  }

  .mainContent {
    padding: 1rem;
    overflow-x: hidden;
    max-width: 100%;
  }

  .headerSection {
    gap: 1rem;
    margin-bottom: 2.5rem;
    padding: 0 0.5rem;
  }

  .logo {
    height: 28px;
  }

  /* Mobile content adjustments */
  .contentSection {
    gap: 0;
  }

  .titleRow {
    gap: 0;
    margin-bottom: 0.5rem;
    padding-right: 0;
  }

  .mainTitle {
    font-size: 1.25rem;
    line-height: 1.2;
    margin-bottom: 0;
  }

  .tagline {
    font-size: 1rem;
    line-height: 1.3;
    text-align: right;
    align-self: flex-end;
    margin-left: auto;
  }

  /* Mobile info boxes */
  .infoBoxContainer {
    margin: 1rem 0;
    padding: 0;
  }

  .infoTitle {
    font-size: 1.25rem;
    margin-bottom: 0.25rem;
    font-weight: bold;
  }

  .infoText {
    font-size: 0.8rem;
    line-height: 1.4;
  }

  .descriptionRow {
    flex-direction: column;
    display: flex;
  }

  /* Mobile button and footer */
  .startButton {
    margin-bottom: -1.5rem;
    margin-top: 1rem;
    padding: 0.75rem 1.5rem;
    border-radius: 100px;
    font-size: 1rem;
    font-weight: 500;
  }

  .description {
    font-size: 0.8rem;
    line-height: 1.4;
    text-align: center;
    margin-top: 1rem;
    margin-bottom: 1rem;
  }

  /* Hide decorative circles on smaller screens */
  .decorativeCircle {
    display: none;
  }

  .poweredBy {
    font-size: 0.875rem;
  }
}