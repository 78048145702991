/* UniversityQuiz.module.css */

/* Quiz wrapper and background */
.quizWrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  width: 100%;
  max-width: 100vw;
  overflow-x: hidden;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  justify-content: flex-start;
}

/* Animated Background */
.animatedBackground {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 0;
  overflow: hidden;
  pointer-events: none;
}

.backgroundCanvas {
  width: 100%;
  height: 100%;
  display: block;
  transform-origin: center center;
  will-change: transform;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  backface-visibility: hidden;
  transform: translateZ(0);
  image-rendering: -webkit-optimize-contrast;
  image-rendering: crisp-edges;
}


/* Quiz Container */
.quizContainer {
  position: relative;
  z-index: 1;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  width: 100%;
  padding: 20px 20px 0;
  box-sizing: border-box;
  font-weight: 400;
  justify-content: flex-start;
  background-color: transparent;
}

/* Header Styles */
.header {
  display: grid;
  grid-template-columns: 120px 1fr 120px;
  align-items: center;
  gap: 20px;
  margin-bottom: 20px;
  position: relative;
  z-index: 2;
}

.logo {
  height: 40px;
  width: auto;
  opacity: 0;
  animation: fadeIn 0.5s ease forwards;
}

.questionCount {
  font-size: 14px;
  white-space: nowrap;
  font-weight: bold;
  opacity: 0;
  animation: fadeIn 0.5s ease forwards;
  margin-left: auto;
}

/* Progress Bar */
.progressWrapper {
  width: 100%;
  max-width: 300px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
  justify-self: center;
}

.progressBar {
  width: 100%;
  height: 8px;
  background-color: rgba(255, 255, 255, 0.3);
  border-radius: 4px;
  overflow: hidden;
}

.progressFill {
  height: 100%;
  border-radius: 4px;
  transition: width 0.5s ease;
}

/* Quiz Content */
.quizContent {
  width: 100%;
  max-width: 600px;
  margin: 0 auto;
  flex: 1 0 auto;
  display: flex;
  flex-direction: column;
  gap: 15px;
  position: relative;
  z-index: 2;
}

.questionText {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 20px;
  text-align: center;
  opacity: 0;
  animation: fadeIn 0.5s ease forwards;
}

/* Answer Options */
.answerOptions {
  display: flex;
  flex-direction: column;
  gap: 12px;
  margin-bottom: 0;
  flex: 1 0 auto;
}

.answerButton {
  background-color: rgba(255, 255, 255, 0.9);
  border: 2px solid;
  border-radius: 8px;
  padding: 15px 20px;
  font-size: 16px;
  cursor: pointer;
  transition: all 0.3s ease;
  text-align: left;
  font-weight: 500;
  opacity: 0;
  transform: translateY(20px);
  animation: fadeInUp 0.5s ease forwards;
  position: relative;
  overflow: hidden;
}

.answerButton:nth-child(1) { animation-delay: 0.1s; }
.answerButton:nth-child(2) { animation-delay: 0.2s; }
.answerButton:nth-child(3) { animation-delay: 0.3s; }
.answerButton:nth-child(4) { animation-delay: 0.4s; }

.answerButton:hover {
  background-color: currentColor;
  transform: translateY(-2px);
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

.answerButton:hover span {
  color: white;
}

.answerButton span {
  position: relative;
  z-index: 1;
  transition: color 0.3s ease;
}

/* Back Button */
.backButton {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  padding: 12px 20px;
  border: 2px solid;
  border-radius: 25px;
  background-color: rgba(255, 255, 255, 0.9);
  cursor: pointer;
  font-weight: 500;
  transition: all 0.3s ease;
  margin: 0 auto;
  width: fit-content;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  opacity: 0;
  transform: translateY(20px);
  animation: fadeInUp 0.5s ease forwards 0.5s;
}

.backButton:hover {
  opacity: 0.9;
  transform: translateY(-2px);
}

.backButton:active {
  transform: translateY(0);
}

/* Form Elements */
.userInfoForm {
  top: 2rem;
  display: flex;
  flex-direction: column;
  gap: 15px;
  width: calc(100% - 32px);
  max-width: 400px;
  margin: 0 auto;
  align-items: center;
  position: relative;
  z-index: 2;
  background-color: rgba(255, 255, 255, 0.9);
  padding: 14px;
  border-radius: 12px;
  box-shadow: 0 8px 32px rgba(0, 0, 0, 0.1);
}

.input {
  width: 100%;
  max-width: 400px;
  padding: 12px;
  border: 1px solid rgba(204, 204, 204, 0.5);
  border-radius: 8px;
  font-size: 16px;
  box-sizing: border-box;
  transition: all 0.3s ease;
  background-color: rgba(255, 255, 255, 0.9);
}

.input:focus {
  outline: none;
  border-color: currentColor;
  box-shadow: 0 0 0 2px rgba(0, 0, 0, 0.1);
}

.submitButton {
  width: 100%;
  max-width: 400px;
  padding: 12px 24px;
  border: none;
  border-radius: 8px;
  font-size: 16px;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.3s ease;
  box-sizing: border-box;
  background-color: currentColor;
  color: white;
}

.submitButton:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
}

.submitButton:active {
  transform: translateY(0);
}

.submitButton:hover:not(:disabled) {
  background-color: #0052a3;
  transform: translateY(-2px);
}

.submitButton:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.buttonSpinner {
  display: inline-block;
  width: 16px;
  height: 16px;
  margin-right: 8px;
  border: 2px solid rgba(255, 255, 255, 0.3);
  border-radius: 50%;
  border-top-color: #fff;
  animation: spin 1s ease-in-out infinite;
  vertical-align: text-bottom;
}

.smallSpinner {
  display: inline-block;
  width: 12px;
  height: 12px;
  margin-right: 6px;
  border: 1.5px solid rgba(0, 102, 204, 0.3);
  border-radius: 50%;
  border-top-color: #0066cc;
  animation: spin 1s ease-in-out infinite;
  vertical-align: text-bottom;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

/* Modify the existing submit button styles to properly align spinner */
.submitButton, .verifyButton {
  display: flex;
  align-items: center;
  justify-content: center;
}

/* For RTL support */
[dir="rtl"] .buttonSpinner, 
[dir="rtl"] .smallSpinner {
  margin-right: 0;
  margin-left: 8px;
}

/* Footer */
.footer {
  width: 100%;
  padding: 8px 0;
  margin-top: auto;
  flex-shrink: 0;
  position: relative;
  z-index: 2;
}

.poweredBy {
  font-size: 14px;
  font-weight: 700;
  color: #4E529A;
  display: flex;
  align-items: center;
  justify-content: center;
}

.workxplorerIcon {
  height: 20px;
  margin-left: 8px;
}

/* Error Text */
.errorText {
  color: #ff6b6b;
  font-size: 14px;
  text-align: center;
  animation: shake 0.5s ease;
}

.confidentialityPolicy{
  font-size: 14px;
  text-align: center;
}

.confidentialityPolicy a{
  font-weight: bold;
  color:#6569b7;
}

/* Basic Animations */
@keyframes fadeIn {
  from { opacity: 0; }
  to { opacity: 1; }
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes shake {
  0%, 100% { transform: translateX(0); }
  25% { transform: translateX(-5px); }
  75% { transform: translateX(5px); }
}

/* RTL Support */
[dir="rtl"] .workxplorerIcon {
  margin-left: 0;
  margin-right: 8px;
}

[dir="rtl"] .quizWrapper {
  direction: rtl;
}

/* Media Queries */
@media (max-width: 1024px) {
  .header {
    grid-template-columns: 1fr;
    gap: 15px;
    text-align: center;
  }

  .logo {
    justify-self: center;
  }

  .questionCount {
    margin-left: 0;
    justify-self: center;
  }

  .progressWrapper {
    max-width: 300px;
    margin: 10px auto;
  }

  .backButton {
    padding: 10px 16px;
    font-size: 14px;
  }
}

@media (max-width: 768px) {
  .quizWrapper {
    padding: 1rem;
  }

  .questionText {
    font-size: 20px;
  }

  .answerButton {
    padding: 12px 16px;
    font-size: 14px;
  }

}

/* Performance optimizations for different browsers */
@supports (-webkit-appearance:none) {
  .backgroundCanvas {
    transform: translate3d(0,0,0);
    -webkit-transform: translate3d(0,0,0);
  }
}

/* Accessibility */
@media (prefers-reduced-motion: reduce) {
  .animatedBackground canvas {
    animation: none !important;
    transition: none !important;
  }

  .backgroundCanvas {
    animation: none !important;
    transition: none !important;
  }

  .answerButton,
  .backButton,
  .submitButton {
    transition: none !important;
  }
}

/* Mobile Browser Fixes */
@supports (-webkit-touch-callout: none) {
  .animatedBackground {
    height: -webkit-fill-available;
  }

  .quizContainer {
    min-height: -webkit-fill-available;
  }
  
  .quizWrapper {
    height: -webkit-fill-available;
  }
}

/* Firefox Fix */
@-moz-document url-prefix() {
  .quizWrapper {
    min-height: 100vh;
  }
}

/* Performance Optimizations */
.animatedBackground {
  transform: translateZ(0);
  backface-visibility: hidden;
}

/* Print styles */
@media print {
  .animatedBackground {
    display: none;
  }
  
  .quizWrapper {
    background-color: white;
    min-height: auto;
  }
}

/* iOS Input Zoom Fix */
@media screen and (max-width: 768px) {
  input[type="text"],
  input[type="tel"],
  input[type="email"],
  select {
    font-size: 16px !important;
  }
}

/* Scrollbar Fix */
html {
  width: 100vw;
  overflow-x: hidden;
}