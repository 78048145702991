@import url('https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,200..1000;1,200..1000&display=swap');

.container {
  min-height: 100vh;
  margin: 0;
  font-family: "Nunito", Ubuntu, sans-serif;
  font-display: swap;
  font-optical-sizing: auto;
  background: linear-gradient(135deg, #40437f 0%, #242648 100%);
  color: #FAFAFA;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
}

.hero {
  position: relative;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  text-align: center;
  padding: 2rem;
  overflow: hidden;
  padding-top: 6rem;
}

.hero::before {
  content: '';
  position: absolute;
  width: 150%;
  height: 150%;
  background: radial-gradient(circle at center, rgba(64, 67, 127, 0.1) 0%, transparent 70%);
  top: -25%;
  left: -25%;
  z-index: 0;
  animation: pulse 8s infinite;
}

.content {
  position: relative;
  z-index: 1;
  max-width: 1200px;
  margin: 0 auto;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.language_selector {
  position: fixed;
  top: 2rem;
  right: 2rem;
  z-index: 10;
}

.lang_dropdown {
  position: relative;
  display: inline-block;
}

.lang_selected {
  background: rgba(255, 255, 255, 0.1);
  border: 1px solid rgba(255, 255, 255, 0.2);
  color: #FAFAFA;
  padding: 0.5rem 2.5rem 0.5rem 1rem;
  border-radius: 6px;
  cursor: pointer;
  transition: all 0.3s ease;
  font-size: 0.9rem;
  backdrop-filter: blur(10px);
  position: relative;
  display: flex;
  align-items: center;
  min-width: 100px;
}

.lang_selected::after {
  content: '';
  position: absolute;
  right: 1rem;
  top: 50%;
  transform: translateY(-50%);
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 5px solid #FAFAFA;
  transition: transform 0.3s ease;
}

.lang_dropdown.open .lang_selected::after {
  transform: translateY(-50%) rotate(180deg);
}

.lang_options {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  margin-top: 0.5rem;
  background: rgba(36, 38, 72, 0.95);
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 6px;
  overflow: hidden;
  opacity: 0;
  visibility: hidden;
  transform: translateY(-10px);
  transition: all 0.3s ease;
  backdrop-filter: blur(10px);
}

.lang_dropdown.open .lang_options {
  opacity: 1;
  visibility: visible;
  transform: translateY(0);
}

.lang_option {
  padding: 0.5rem 1rem;
  color: #FAFAFA;
  cursor: pointer;
  transition: background-color 0.3s ease;
  text-align: left;
  width: 100%;
  border: none;
  background: none;
  font-size: 0.9rem;
}

.lang_option:hover {
  background: rgba(255, 255, 255, 0.1);
}

.lang_option.active {
  background: rgba(255, 255, 255, 0.15);
}

.logo_container {
  margin-bottom: 2rem;
  transform: translateY(-20px);
  opacity: 0;
  animation: fadeInUp 0.8s ease forwards;
}

.logo {
  width: 120px;
  height: 120px;
  filter: drop-shadow(0 0 20px rgba(64, 67, 127, 0.3));
}

.title {
  font-size: 4rem;
  font-weight: 800;
  background: linear-gradient(135deg, #fff 0%, rgba(255,255,255,0.8) 100%);
  background-clip: text; /* Add this line */
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  margin: 1rem 0;
  transform: translateY(-20px);
  opacity: 0;
  animation: fadeInUp 0.8s ease 0.2s forwards;
}

.description {
  font-size: 1.25rem;
  max-width: 800px;
  margin: 1.5rem auto;
  line-height: 1.6;
  color: rgba(255, 255, 255, 0.9);
  transform: translateY(-20px);
  opacity: 0;
  animation: fadeInUp 0.8s ease 0.4s forwards;
  padding: 0 1rem;
}

.coming_soon {
  margin: 2rem 0;
  transform: translateY(-20px);
  opacity: 0;
  animation: fadeInUp 0.8s ease 0.6s forwards;
}

.text {
  font-size: 2rem;
  font-weight: 600;
  color: #FAFAFA;
  transition: opacity 0.5s ease-in-out;
}

.audience_buttons {
  display: flex;
  gap: 1rem;
  justify-content: center;
  margin: 2rem 0;
  width: 100%;
  max-width: 600px;
  padding: 0 1rem;
}

.audience_button {
  padding: 1rem 2rem;
  border: 2px solid rgba(255, 255, 255, 0.8);
  border-radius: 8px;
  background: rgba(255, 255, 255, 0.05);
  color: #FAFAFA;
  font-size: 1.1rem;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.3s ease;
  flex: 1;
  white-space: nowrap;
  backdrop-filter: blur(10px);
}

.audience_button:hover {
  background: rgba(255, 255, 255, 0.1);
  transform: translateY(-2px);
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

.audience_button.active {
  background: #FAFAFA;
  color: #40437f;
  border-color: #FAFAFA;
  box-shadow: 0 4px 12px rgba(255, 255, 255, 0.2);
}

.features {
  display: flex;
  justify-content: center;
  gap: 2rem;
  margin-top: 3rem;
  flex-wrap: wrap;
  width: 100%;
  max-width: 1200px;
  padding: 0 2rem; /* Increased padding to prevent overflow */
  box-sizing: border-box; /* Add this to include padding in width calculation */
}

.feature {
  background: rgba(255, 255, 255, 0.05);
  padding: 2rem;
  border-radius: 12px;
  backdrop-filter: blur(10px);
  border: 1px solid rgba(255, 255, 255, 0.1);
  flex: 1;
  min-width: 280px;
  max-width: 350px;
  text-align: center;
  transition: transform 0.3s ease;
  margin: 0; /* Add this to ensure no extra margins */
  box-sizing: border-box; /* Add this to include padding in width calculation */
}

.feature:hover {
  transform: translateY(-5px);
  background: rgba(255, 255, 255, 0.08);
}

.feature h3 {
  color: #FAFAFA;
  margin-bottom: 1rem;
  font-size: 1.25rem;
}

.feature p {
  color: rgba(255, 255, 255, 0.8);
  font-size: 0.95rem;
  line-height: 1.5;
}

.construction_badge {
  position: fixed;
  bottom: 2rem;
  left: 2rem;
  background: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(10px);
  border: 1px solid rgba(255, 255, 255, 0.2);
  padding: 0.5rem 1rem;
  border-radius: 20px;
  font-size: 0.85rem;
  color: rgba(255, 255, 255, 0.7);
  z-index: 10;
  transition: all 0.3s ease;
}

.footer {
  position: relative;
  z-index: 1;
  padding: 2rem;
  background: rgba(0, 0, 0, 0.2);
  backdrop-filter: blur(10px);
  margin-top: auto;
}

.footer_content {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1.5rem;
}

.footer_links {
  display: flex;
  justify-content: center;
  gap: 3rem;
  flex-wrap: wrap;
}

.link {
  color: #FAFAFA;
  text-decoration: none;
  font-size: 0.9rem;
  opacity: 0.7;
  transition: all 0.3s ease;
}

.link:hover {
  opacity: 1;
}

.copyright {
  color: #FAFAFA;
  opacity: 0.7;
  font-size: 0.85rem;
  text-align: center;
  margin-top: 1rem;
}

@keyframes pulse {
  0% { transform: scale(1); opacity: 0.5; }
  50% { transform: scale(1.2); opacity: 0.3; }
  100% { transform: scale(1); opacity: 0.5; }
}

@keyframes fadeInUp {
  from {
    transform: translateY(20px);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

@media (max-width: 992px) {
  .features {
    flex-direction: column;
    align-items: center;
  }

  .feature {
    width: 100%;
    max-width: none;
    min-width: 0;
    margin: 0;
  }
}


@media (max-width: 768px) {
  .hero {
    padding: 1rem;
    padding-top: 5rem;
  }

  .language_selector {
    top: 1rem;
    right: 1rem;
  }

  .logo_container {
    margin-bottom: 1.5rem;
  }

  .logo {
    width: 80px;
    height: 80px;
  }

  .title {
    font-size: 2.5rem;
    margin: 0.5rem 0;
    line-height: 1.2;
  }

  .description {
    font-size: 1rem;
    margin: 1rem 0;
    line-height: 1.5;
  }

  .audience_buttons {
    flex-direction: column;
    padding: 0 2rem;
  }

  .audience_button {
    width: 100%;
    padding: 0.875rem 1.5rem;
    font-size: 1rem;
  }

  .construction_badge {
    left: 1rem;
    bottom: 1rem;
    transform: none;
    white-space: nowrap;
    font-size: 0.75rem;
    padding: 0.375rem 0.75rem;
  }

  .features {
    gap: 1rem;
    margin-top: 2rem;
    padding: 0 1rem;
  }

  .feature {
    padding: 1.5rem;
  }
}

@media (max-width: 360px) {
  .hero {
    padding-top: 4rem;
  }

  .title {
    font-size: 2rem;
  }

  .description {
    font-size: 0.875rem;
  }

  .audience_button {
    font-size: 0.875rem;
    padding: 0.75rem 1rem;
  }

  .feature {
    padding: 1.25rem;
  }

  .feature h3 {
    font-size: 1.1rem;
  }

  .feature p {
    font-size: 0.875rem;
  }
}