
.quizWrapper {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  width: 100%;
  max-width: 100vw;
  overflow-x: hidden;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  background-color: #f8fafc;
  position: relative;
}

.error {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 2rem 1rem;
  text-align: center;
  color: rgb(141, 0, 0);
  min-height: 50vh;
}

.retryButton {
  margin-top: 1rem;
  padding: 0.75rem 1.5rem;
  background-color: rgb(37, 99, 235);
  color: white;
  border: none;
  border-radius: 0.5rem;
  cursor: pointer;
  font-size: 0.875rem;
  font-weight: 500;
  transition: background-color 0.2s;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.retryButton:hover {
  background-color: rgb(29, 78, 216);
}

.retryButton:active {
  transform: translateY(1px);
}

/* Additional accessibility styles */
.retryButton:focus {
  outline: none;
  box-shadow: 0 0 0 3px rgba(37, 99, 235, 0.4);
}

a {
  color: inherit; /* blue colors for links too */
  text-decoration: inherit; /* no underline */
}

/* Additional mobile styles */
@media (max-width: 480px) {
  .error {
    padding: 1.5rem 1rem;
  }

  .retryButton {
    width: 100%;
    max-width: 300px;
    padding: 0.875rem 1rem;
  }
}

/* Ensure proper height on mobile browsers */
@supports (-webkit-touch-callout: none) {
  .quizWrapper {
    min-height: -webkit-fill-available;
  }
}

/* Fix for Firefox */
@-moz-document url-prefix() {
  .quizWrapper {
    min-height: 100vh;
  }
}

/* Additional RTL support */
[dir="rtl"] .quizWrapper {
  direction: rtl;
}

/* Print styles */
@media print {
  .quizWrapper {
    background-color: white;
    min-height: auto;
  }

  .retryButton {
    display: none;
  }
}

/* Fix for iOS input zoom */
@media screen and (max-width: 768px) {
  input[type="text"],
  input[type="tel"],
  input[type="email"],
  select {
    font-size: 16px !important;
  }
}

/* Fix for horizontal scroll on older browsers */
html, body {
  overflow-x: hidden;
  width: 100%;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

/* Fix for content jumping when scrollbar appears */
html {
  width: 100vw;
  overflow-x: hidden;
}

/* Maintain aspect ratio for responsive containers */
.aspectRatioBox {
  position: relative;
  width: 100%;
  height: 0;
  padding-top: 56.25%; /* 16:9 Aspect Ratio */
}

.aspectRatioBox > * {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

/* Focus visible polyfill styles */
.js-focus-visible :focus:not(.focus-visible) {
  outline: none;
}

.js-focus-visible .focus-visible {
  outline: 2px solid rgb(37, 99, 235);
  outline-offset: 2px;
}