.otpContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 24px;
    width: 100%;
    max-width: 400px;
    margin: 0 auto;
    background-color: rgba(255, 255, 255, 0.9);
    padding: 24px;
    border-radius: 12px;
    box-shadow: 0 8px 32px rgba(0, 0, 0, 0.1);
  }
  
  .otpHeader {
    text-align: center;
  }
  
  .otpHeader h2 {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 8px;
  }
  
  .otpHeader p {
    color: #666;
    font-size: 16px;
  }
  
  .otpInputContainer {
    display: flex;
    gap: 12px;
    justify-content: center;
    width: 100%;
  }
  
  /* Remove spinner buttons for all WebKit browsers (Chrome, Safari, Edge, Opera) */
  .otpInput::-webkit-outer-spin-button,
  .otpInput::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Remove spinner for Firefox */
  .otpInput[type=number] {
    -moz-appearance: textfield;
    appearance: textfield; /* Standard property for future compatibility */
  }

  .otpInput {
    width: 56px;
    height: 56px;
    text-align: center;
    font-size: 24px;
    border: 2px solid #ddd;
    border-radius: 8px;
    background-color: white;
    transition: all 0.3s ease;
    -webkit-appearance: none;
    appearance: none;
  }
  
  .otpInput:focus {
    outline: none;
    border-color: #0066cc;
    box-shadow: 0 0 0 2px rgba(0, 102, 204, 0.2);
  }
  
  .errorText {
    color: #ff4444;
    font-size: 14px;
    text-align: center;
    animation: shake 0.5s ease;
  }
  
  .verifyButton {
    width: 100%;
    padding: 14px 24px;
    border: none;
    border-radius: 8px;
    font-size: 16px;
    font-weight: 500;
    background-color: #0066cc;
    color: white;
    cursor: pointer;
    transition: all 0.3s ease;
  }
  
  .verifyButton:hover:not(:disabled) {
    background-color: #0052a3;
    transform: translateY(-2px);
  }
  
  .verifyButton:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
  
  .otpFooter {
    text-align: center;
  }
  
  .attemptsText {
    color: #666;
    font-size: 14px;
    margin-bottom: 4px;
  }
  
  .resendAttemptsText {
    color: #666;
    font-size: 14px;
    margin-bottom: 8px;
  }
  
  .cooldownText {
    color: #666;
    font-size: 14px;
  }
  
  .resendButton {
    color: #0066cc;
    font-size: 14px;
    font-weight: 500;
    background: none;
    border: none;
    cursor: pointer;
    padding: 0;
  }
  
  .resendButton:hover:not(:disabled) {
    text-decoration: underline;
  }
  
  .resendButton:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
  
  @keyframes shake {
    0%, 100% { transform: translateX(0); }
    25% { transform: translateX(-5px); }
    75% { transform: translateX(5px); }
  }
  
  .buttonSpinner {
    display: inline-block;
    width: 16px;
    height: 16px;
    margin-right: 8px;
    border: 2px solid rgba(255, 255, 255, 0.3);
    border-radius: 50%;
    border-top-color: #fff;
    animation: spin 1s ease-in-out infinite;
    vertical-align: text-bottom;
  }
  
  .smallSpinner {
    display: inline-block;
    width: 12px;
    height: 12px;
    margin-right: 6px;
    border: 1.5px solid rgba(0, 102, 204, 0.3);
    border-radius: 50%;
    border-top-color: #0066cc;
    animation: spin 1s ease-in-out infinite;
    vertical-align: text-bottom;
  }
  
  @keyframes spin {
    to {
      transform: rotate(360deg);
    }
  }
  
  /* Update the existing buttons to align content properly */
  .verifyButton {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .resendButton {
    display: inline-flex;
    align-items: center;
    justify-content: center;
  }
  
  /* For RTL support */
  [dir="rtl"] .buttonSpinner, 
  [dir="rtl"] .smallSpinner {
    margin-right: 0;
    margin-left: 8px;
  }

  /* Mobile Responsiveness */
  @media (max-width: 480px) {
    .otpContainer {
      padding: 20px;
    }
  
    .otpInput {
      width: 38px;
      height: 38px;
      font-size: 20px;
    }
  
    .otpHeader h2 {
      font-size: 20px;
    }
  }